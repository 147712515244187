import { useEffect, useState } from "react";
import { useToast } from '@/components/ui/use-toast';
import { useUser } from '@/lib/hooks/useUser';
import { Button } from '@/components/ui/button';
import { Loader2 } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { cn } from '@/lib/utils';

interface LemonSqueezyOverlayProps {
  variantId: string;
  packageId: string;
  onSuccess?: () => void;
  buttonLabel?: string;
  buttonClassName?: string;
}

declare global {
  interface Window {
    createLemonSqueezy: () => void;
    LemonSqueezy: {
      Url: {
        Open: (url: string) => void;
      };
    };
  }
}

export function LemonSqueezyOverlay({ 
  variantId, 
  packageId,
  onSuccess, 
  buttonLabel = "Purchase Credits",
  buttonClassName = ""
}: LemonSqueezyOverlayProps) {
  const [lemonLoaded, setLemonLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const { user } = useUser();

  useEffect(() => {
    // Only log in development
    if (process.env.NODE_ENV === 'development') {
      console.log('LemonSqueezyOverlay mounted');
      console.log('Current lemonLoaded state:', lemonLoaded);
      console.log('Window.LemonSqueezy exists:', !!window.LemonSqueezy);
    }
    
    if (!lemonLoaded && !window.LemonSqueezy) {
      loadLemonSqueezyScript();
    }
  }, [lemonLoaded]);

  useEffect(() => {
    if (window.LemonSqueezy) {
      window.LemonSqueezy.Setup({
        eventHandler: async (event: any) => {
          if (event.event === 'checkout:complete') {
            try {
              // Wait a few seconds for the payment webhook to process
              await new Promise(resolve => setTimeout(resolve, 3000));
              
              // Verify credits were added
              const { data: profile, error } = await supabase
                .from('user_profiles')
                .select('credits')
                .eq('id', user?.id)
                .single();

              if (error || !profile) {
                throw new Error('Failed to verify credit adjustment');
              }

              onSuccess?.();
              toast({
                title: "Purchase Successful",
                description: "Your credits have been added to your account.",
              });
            } catch (error) {
              console.error('Credit verification error:', error);
              toast({
                title: "Credit Adjustment Error",
                description: "Please contact support if credits are not added.",
                variant: "destructive",
              });
            }
          }
        }
      });
    }
  }, [user, onSuccess]);

  const loadLemonSqueezyScript = () => {
    // Check if script is already loaded
    if (document.querySelector('script[src="https://assets.lemonsqueezy.com/lemon.js"]')) {
      setLemonLoaded(true);
      return;
    }

    const script = document.createElement("script");
    script.src = "https://assets.lemonsqueezy.com/lemon.js";
    script.defer = true;
    script.async = true;
    
    script.onload = () => {
      console.log('Lemon Squeezy script loaded');
      if (typeof window.createLemonSqueezy === 'function') {
        window.createLemonSqueezy();
        setLemonLoaded(true);
      } else {
        console.error('createLemonSqueezy function not found');
      }
    };

    script.onerror = (error) => {
      console.error('Error loading Lemon Squeezy script:', error);
      toast({
        title: "Error",
        description: "Failed to load payment system. Please try again later.",
        variant: "destructive",
      });
    };

    document.body.appendChild(script);

    // Add event listeners
    const handleSuccess = () => {
      toast({
        title: "Payment Successful",
        description: "Your credits will be added shortly.",
      });
      onSuccess?.();
    };

    const handleClose = () => {
      onSuccess?.();
    };

    document.addEventListener('lemonsqueezy:successful-payment', handleSuccess);
    document.addEventListener('lemonsqueezy:checkout-closed', handleClose);

    return () => {
      const existingScript = document.querySelector('script[src="https://assets.lemonsqueezy.com/lemon.js"]');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
      document.removeEventListener('lemonsqueezy:successful-payment', handleSuccess);
      document.removeEventListener('lemonsqueezy:checkout-closed', handleClose);
    };
  };

  const openCheckout = async () => {
    if (!user) {
      toast({
        title: "Error",
        description: "Please login to purchase credits",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsLoading(true);
      
      // Validate variant ID format
      if (!variantId || !variantId.match(/^[0-9]+$/)) {
        console.error('Invalid variant ID:', variantId);
        throw new Error('Invalid variant ID format');
      }

      const checkoutData = {
        type: 'create-checkout',
        variantId,
        packageId,
        userId: user.id,
        email: user.email,
      };

      console.log('Sending checkout request:', checkoutData);
      
      const response = await fetch(
        `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/payment-handler`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_ANON_KEY}`,
          },
          body: JSON.stringify(checkoutData),
        }
      );

      const data = await response.json();
      console.log('Checkout response:', data);
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to create checkout');
      }

      if (window.LemonSqueezy?.Url) {
        window.LemonSqueezy.Url.Open(data.checkoutUrl);
      }
    } catch (error) {
      console.error('Error creating checkout:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to initialize payment",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={openCheckout}
      disabled={isLoading || !lemonLoaded}
      className={cn("flex items-center gap-2", buttonClassName)}
    >
      {isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Processing...
        </>
      ) : !lemonLoaded ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Loading...
        </>
      ) : (
        buttonLabel
      )}
    </Button>
  );
} 