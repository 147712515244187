import React, { useState } from 'react';
import { useUser } from '@/lib/hooks/useUser';
import { PaymentService } from '@/lib/services/PaymentService';
import { LemonSqueezyOverlay } from '@/components/payment/LemonSqueezyOverlay';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useSubscription } from '@/lib/hooks/useSubscription';
import { useToast } from '@/components/ui/use-toast';
import { Badge } from '@/components/ui/badge';
import { Loader2, CreditCard, Calendar, Package, DollarSign, AlertTriangle, Package2, Check } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { cn } from '@/lib/utils';

export function SubscriptionManagement() {
  const { user } = useUser();
  const { subscription, loading, error } = useSubscription();
  const { toast } = useToast();
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  
  const handleManageSubscription = async () => {
    try {
      if (!subscription?.lemon_squeezy_subscription_id) {
        throw new Error('No subscription ID found');
      }

      // Get the portal URL from our Supabase function
      const response = await fetch(
        `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/subscription-manager`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_ANON_KEY}`,
          },
          body: JSON.stringify({
            action: 'get-portal-url',
            subscription_id: subscription.lemon_squeezy_subscription_id,
          }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        console.error('Subscription portal error:', error);
        throw new Error(error.error || 'Failed to get portal URL');
      }

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Error opening subscription portal:', error);
      toast({
        title: 'Error',
        description: 'Failed to open subscription management portal',
        variant: 'destructive',
      });
    }
  };

  const handleCancelSubscription = async () => {
    try {
      setCancelling(true);
      console.log('Starting subscription cancellation...'); // Debug log
      
      const response = await fetch(
        `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/subscription-manager`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_ANON_KEY}`,
          },
          body: JSON.stringify({
            action: 'cancel',
            subscription_id: subscription?.lemon_squeezy_subscription_id,
          }),
        }
      );

      console.log('Response status:', response.status); // Debug log
      const data = await response.json();
      console.log('Response data:', data); // Debug log

      if (!response.ok) {
        console.error('Cancellation error response:', data);
        throw new Error(data.error || 'Failed to cancel subscription');
      }

      toast({
        title: 'Success',
        description: data.message || 'Your subscription will be cancelled at the end of the billing period',
      });

      // Refresh the subscription data instead of full page reload
      window.location.reload();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to cancel subscription. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setCancelling(false);
      setShowCancelDialog(false);
    }
  };

  if (loading) {
    return (
      <Card className="w-full">
        <CardContent className="pt-6 flex items-center justify-center min-h-[400px]">
          <div className="flex flex-col items-center gap-4">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
            <p className="text-muted-foreground">Loading subscription details...</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="w-full border-destructive">
        <CardContent className="pt-6">
          <div className="flex flex-col items-center gap-4 text-center p-8">
            <AlertTriangle className="h-12 w-12 text-destructive" />
            <div className="space-y-2">
              <h3 className="font-semibold text-lg">Failed to load subscription</h3>
              <p className="text-muted-foreground">Please try refreshing the page or contact support if the issue persists.</p>
            </div>
            <Button onClick={() => window.location.reload()} variant="outline">
              Retry
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!subscription) {
    return (
      <Card className="w-full">
        <CardContent className="pt-6">
          <div className="flex flex-col items-center gap-4 text-center p-8">
            <Package className="h-12 w-12 text-muted-foreground" />
            <div className="space-y-2">
              <h3 className="font-semibold text-lg">No Active Subscription</h3>
              <p className="text-muted-foreground">Choose a subscription plan to get started with our services.</p>
            </div>
            <Button asChild>
              <a href="/credits">View Plans</a>
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  const getStatusBadgeVariant = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return 'success';
      case 'cancelled':
      case 'expired':
        return 'destructive';
      case 'past_due':
        return 'warning';
      default:
        return 'secondary';
    }
  };

  return (
    <>
      <Card className="w-full">
        <CardHeader className="pb-6">
          <div className="flex items-center justify-between">
            <div>
              <CardTitle className="text-xl font-medium">Subscription Details</CardTitle>
              <CardDescription className="mt-1">
                Manage your subscription and billing preferences
              </CardDescription>
            </div>
            <Badge 
              variant={getStatusBadgeVariant(subscription.status)}
              className="capitalize px-3 py-1"
            >
              {subscription.status}
            </Badge>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid gap-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div className="flex items-start gap-3">
                  <Package className="h-5 w-5 text-muted-foreground mt-1" />
                  <div>
                    <h4 className="text-sm text-muted-foreground">Current Plan</h4>
                    <p className="mt-1">{subscription.package.name}</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <Calendar className="h-5 w-5 text-muted-foreground mt-1" />
                  <div>
                    <h4 className="text-sm text-muted-foreground">Next Payment</h4>
                    <p className="mt-1">
                      {new Date(subscription.current_period_end).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                <div className="flex items-start gap-3">
                  <CreditCard className="h-5 w-5 text-muted-foreground mt-1" />
                  <div>
                    <h4 className="text-sm text-muted-foreground">Monthly Credits</h4>
                    <p className="mt-1">{subscription.package.credits_per_month.toLocaleString()}</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <DollarSign className="h-5 w-5 text-muted-foreground mt-1" />
                  <div>
                    <h4 className="text-sm text-muted-foreground">Monthly Price</h4>
                    <p className="mt-1">${subscription.package.price}</p>
                  </div>
                </div>
              </div>
            </div>

            {subscription.cancel_at_period_end && (
              <div className="rounded-lg border-2 border-amber-500/30 bg-amber-50 dark:bg-yellow-900/40 p-4">
                <div className="flex gap-3">
                  <AlertTriangle className="h-5 w-5 text-amber-600 dark:text-yellow-400 mt-0.5 flex-shrink-0" />
                  <div className="space-y-1.5">
                    <p className="text-sm font-medium text-amber-800 dark:text-yellow-300">
                      Subscription Ending Soon
                    </p>
                    <p className="text-sm text-amber-700 dark:text-yellow-200">
                      Your subscription will end on{' '}
                      <span className="font-medium text-amber-800 dark:text-yellow-300">
                        {new Date(subscription.current_period_end).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </span>
                    </p>
                    <p className="text-sm text-amber-700 dark:text-yellow-300/90">
                      You can reactivate your subscription before it expires to maintain access.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CardContent>
        <CardFooter className="flex flex-col sm:flex-row gap-3 pt-6 border-t">
          <Button 
            onClick={handleManageSubscription}
            variant="default"
            className="w-full sm:w-auto"
          >
            Update Plan
          </Button>
          {!subscription.cancel_at_period_end && (
            <Button 
              variant="outline"
              onClick={() => setShowCancelDialog(true)}
              className="w-full sm:w-auto text-destructive hover:text-destructive-foreground hover:bg-destructive"
            >
              Cancel Subscription
            </Button>
          )}
        </CardFooter>
      </Card>

      <div className="mt-8">
        <div className="flex items-center gap-2 mb-6">
          <Package2 className="h-5 w-5 text-muted-foreground" />
          <h2 className="text-lg">Available Plans</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {subscription.available_packages?.map((plan) => (
            <Card 
              key={plan.id}
              className={cn(
                "relative flex flex-col border-primary/20 transition-all duration-300",
                "hover:border-primary hover:shadow-[0_0_1rem_rgba(var(--primary),.15)]",
                subscription.package.id === plan.id && "border-primary bg-primary/5"
              )}
            >
              {subscription.package.id === plan.id && (
                <div className="absolute -top-3 left-1/2 -translate-x-1/2">
                  <Badge variant="success" className="px-3">Current Plan</Badge>
                </div>
              )}
              <CardHeader>
                <CardTitle className="text-lg">{plan.name}</CardTitle>
                <CardDescription>{plan.description}</CardDescription>
              </CardHeader>
              <CardContent className="flex-1">
                <div className="text-center mb-4">
                  <div className="text-3xl font-bold group-hover:text-primary transition-colors">
                    ${plan.price}
                    <span className="text-base text-muted-foreground font-normal ml-1">
                      /month
                    </span>
                  </div>
                  <div className="text-sm text-muted-foreground mt-1">
                    {plan.credits_per_month.toLocaleString()} credits monthly
                  </div>
                </div>
                <ul className="space-y-2">
                  {plan.features?.map((feature, index) => (
                    <li key={index} className="flex items-start gap-2">
                      <Check className="h-4 w-4 text-primary mt-1" />
                      <span className="text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter>
                {subscription.package.id === plan.id ? (
                  <Button 
                    variant="outline" 
                    className="w-full" 
                    disabled
                  >
                    Current Plan
                  </Button>
                ) : (
                  <Button 
                    className="w-full"
                    asChild
                  >
                    <a href="/credits">Select Plan</a>
                  </Button>
                )}
              </CardFooter>
            </Card>
          ))}
        </div>

        <div className="mt-4 text-sm text-muted-foreground text-center">
          <p>
            Need a custom plan?{' '}
            <a 
              href="/support/tickets"
              className="text-primary hover:underline"
            >
              Contact our support team
            </a>
          </p>
        </div>
      </div>

      <AlertDialog open={showCancelDialog} onOpenChange={setShowCancelDialog}>
        <AlertDialogContent className="max-w-md">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-lg font-medium">
              Cancel Subscription
            </AlertDialogTitle>
            <AlertDialogDescription className="mt-3 text-sm text-muted-foreground">
              Are you sure you want to cancel? You'll continue to have access until{' '}
              <span className="text-foreground">
                {new Date(subscription.current_period_end).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </span>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="mt-6 gap-3">
            <AlertDialogCancel className="w-full sm:w-auto">
              Keep Subscription
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleCancelSubscription}
              className="w-full sm:w-auto bg-destructive text-destructive-foreground hover:bg-destructive/90"
              disabled={cancelling}
            >
              {cancelling ? (
                <div className="flex items-center gap-2">
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Cancelling...</span>
                </div>
              ) : (
                'Yes, Cancel Subscription'
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
} 