import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import type { ActiveJob, ValidationResult } from '@/types/validation';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { Button } from '@/components/ui/button';
import { ArrowLeft, CheckCircle, Clock, XCircle, Wifi, WifiOff } from 'lucide-react';
import { formatDistanceToNow, addMinutes } from 'date-fns';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { useToast } from '@/hooks/use-toast';
import { useAuth } from '@/hooks/use-auth';
import { ProviderName } from '@/types/providers';

interface ValidationHistoryRecord {
  id: string;
  user_id: string;
  file_name: string | null;
  total_emails: number;
  valid_emails: string[];
  invalid_emails: string[];
  status: 'pending' | 'processing' | 'completed' | 'failed';
  created_at: string;
  updated_at: string;
  error: string | null;
  estimated_completion_time: string | null;
  results: any;
  progress_percentage: number;
}

export function ProcessingPage() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { id } = useParams<{ id: string }>();
  const [job, setJob] = useState<ActiveJob | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isRealtimeConnected, setIsRealtimeConnected] = useState(false);
  const pollingInterval = useRef<number | null>(null);
  const { user, loading: authLoading } = useAuth();
  const hasAttemptedFetch = useRef(false);

  const handleJobCompletion = (jobData: ValidationHistoryRecord | ActiveJob) => {
    const hasRedirected = sessionStorage.getItem(`redirect-${jobData.id}`);
    if (!hasRedirected) {
      console.log('Validation completed, redirecting to results');
      sessionStorage.setItem(`redirect-${jobData.id}`, 'true');
      setTimeout(() => {
        // Helper function to safely get number value
        const getNumberValue = (value: any): number => {
          if (typeof value === 'number') return value;
          if (typeof value === 'string') return parseInt(value, 10);
          return 0;
        };

        const validationResult: ValidationResult = {
          id: jobData.id,
          date: new Date('created_at' in jobData ? jobData.created_at : jobData.createdAt),
          status: jobData.status,
          totalEmails: getNumberValue('total_emails' in jobData ? jobData.total_emails : jobData.totalEmails),
          creditsUsed: 'credits_used' in jobData && jobData.credits_used !== undefined ? 
            getNumberValue(jobData.credits_used) : 
            getNumberValue('total_emails' in jobData ? jobData.total_emails : jobData.totalEmails),
          valid: 'valid_emails' in jobData ? jobData.valid_emails : jobData.validEmails,
          invalid: 'invalid_emails' in jobData ? jobData.invalid_emails : jobData.invalidEmails,
          risky: [],
          disposable: [],
          spamTraps: [],
          confidence: 0,
          provider: 'unknown',
          responseTime: 0,
          validationResult: '',
          metadata: {
            fileName: 'file_name' in jobData ? jobData.file_name || undefined : jobData.fileName
          }
        };

        navigate('/validation/results', { 
          state: { validationResult },
          replace: true
        });
      }, 1000);
    }
  };

  const mapToActiveJob = (jobData: ValidationHistoryRecord): ActiveJob => {
    const processedEmails = (jobData.valid_emails?.length || 0) + (jobData.invalid_emails?.length || 0);
    
    return {
      id: jobData.id,
      userId: jobData.user_id,
      fileName: jobData.file_name || 'Untitled Validation',
      totalEmails: jobData.total_emails,
      validEmails: jobData.valid_emails || [],
      invalidEmails: jobData.invalid_emails || [],
      riskyEmails: [],  // These fields might not exist in older records
      disposableEmails: [],
      spamTrapEmails: [],
      confidence: 0,
      provider: 'unknown',
      responseTime: 0,
      validationResult: '',
      processedEmails,
      progress: {
        total: jobData.total_emails,
        processed: processedEmails,
        status: jobData.status,
        percentage: jobData.progress_percentage,
        startTime: new Date(jobData.created_at),
        estimatedEndTime: jobData.estimated_completion_time ? new Date(jobData.estimated_completion_time) : undefined
      },
      status: jobData.status,
      createdAt: new Date(jobData.created_at),
      updatedAt: new Date(jobData.updated_at),
      error: jobData.error
    };
  };

  const fetchJob = async () => {
    if (!user || !id) return;
    
    try {
      const { data: jobData, error } = await supabase
        .from('validation_history')
        .select('*')
        .eq('id', id)
        .eq('user_id', user.id)
        .maybeSingle();

      if (error) throw error;

      if (!jobData) {
        setError('Validation job not found or you do not have permission to view it');
        return;
      }

      const activeJob = mapToActiveJob(jobData);
      setJob(activeJob);

      // Handle completion
      if (jobData.status === 'completed') {
        handleJobCompletion(jobData);
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to fetch validation job');
    }
  };

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!authLoading) {
      if (!user) {
        // Store the current validation ID in localStorage before redirecting
        if (id) {
          localStorage.setItem('pendingValidationId', id);
        }
        navigate('/login');
      } else if (!hasAttemptedFetch.current) {
        // If we have a stored validation ID, restore it
        const storedId = localStorage.getItem('pendingValidationId');
        if (storedId) {
          localStorage.removeItem('pendingValidationId');
          navigate(`/validation/${storedId}/processing`);
        }
        hasAttemptedFetch.current = true;
        fetchJob();
      }
    }
  }, [user, authLoading, navigate, id]);

  // Set up real-time updates
  useEffect(() => {
    if (!user || !id) return;
    
    console.log('Setting up realtime subscription for validation:', id);
    
    // Set up real-time subscription
    const channel = supabase
      .channel('public:validation_history')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'validation_history',
          filter: `id=eq.${id}`
        },
        (payload: RealtimePostgresChangesPayload<any>) => {
          console.log('Received realtime update:', payload);
          if (payload.new && payload.new.id === id) {
            const jobData = payload.new;
            console.log('Processing update:', {
              status: jobData.status,
              progress: jobData.progress_percentage,
              validCount: jobData.valid_emails?.length,
              invalidCount: jobData.invalid_emails?.length
            });

            const activeJob = mapToActiveJob(jobData);
            setJob(activeJob);

            // Handle completion
            if (jobData.status === 'completed') {
              handleJobCompletion(jobData);
            }
          }
        }
      )
      .subscribe((status) => {
        console.log('Subscription status:', status);
        const isConnected = status === 'SUBSCRIBED';
        setIsRealtimeConnected(isConnected);
        
        // If realtime connection fails, start polling
        if (!isConnected && !pollingInterval.current) {
          console.log('Starting polling fallback');
          pollingInterval.current = window.setInterval(fetchJob, 2000);
          toast({
            title: "Realtime Updates Unavailable",
            description: "Falling back to polling for updates every 2 seconds",
            variant: "default",
          });
        }
      });

    // Initial fetch
    fetchJob();

    return () => {
      console.log('Cleaning up realtime subscription');
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
        pollingInterval.current = null;
      }
      channel.unsubscribe();
    };
  }, [id, user]);

  const renderProgress = () => {
    if (!job) return null;

    const progress = (job.validEmails?.length || 0) + (job.invalidEmails?.length || 0);
    const total = job.totalEmails;
    const status = job.status;
    const validCount = job.validEmails?.length || 0;
    const invalidCount = job.invalidEmails?.length || 0;
    const percentComplete = Math.round((progress / total) * 100);

    return (
      <div className="space-y-6 w-full">
        {/* Main Progress */}
        <div className="space-y-2">
          <div className="flex items-center justify-between text-sm">
            <span className="font-medium">
              {status === 'completed' ? 'Validation complete!' : 
               status === 'failed' ? 'Validation failed' :
               `Processing ${progress.toLocaleString()} of ${total.toLocaleString()} emails`}
            </span>
            <span className="font-semibold">{percentComplete}%</span>
          </div>
          <Progress 
            value={percentComplete} 
            className="h-2"
          />
        </div>

        {/* Stats Grid */}
        {progress > 0 && (
          <div className="grid grid-cols-2 gap-4 pt-2">
            <div className="rounded-lg border p-3 text-center">
              <div className="text-sm font-medium text-muted-foreground">Valid Emails</div>
              <div className="mt-1 text-2xl font-semibold text-green-500">
                {validCount.toLocaleString()}
              </div>
              <div className="text-xs text-muted-foreground mt-1">
                {Math.round((validCount / progress) * 100)}% of processed
              </div>
            </div>
            <div className="rounded-lg border p-3 text-center">
              <div className="text-sm font-medium text-muted-foreground">Invalid Emails</div>
              <div className="mt-1 text-2xl font-semibold text-red-500">
                {invalidCount.toLocaleString()}
              </div>
              <div className="text-xs text-muted-foreground mt-1">
                {Math.round((invalidCount / progress) * 100)}% of processed
              </div>
            </div>
          </div>
        )}

        {/* Status Message */}
        {status === 'failed' && (
          <div className="bg-red-50 border border-red-200 rounded-lg p-4 mt-4">
            <div className="flex items-start">
              <XCircle className="h-5 w-5 text-red-500 mt-0.5" />
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Validation Error</h3>
                <div className="mt-1 text-sm text-red-700">
                  {job.error || 'An error occurred during validation'}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  if (authLoading) {
    return (
      <div className="container max-w-2xl mx-auto py-8">
        <Card>
          <CardContent className="py-12">
            <div className="flex flex-col items-center justify-center">
              <Clock className="h-8 w-8 animate-spin text-muted-foreground" />
              <p className="mt-4 text-muted-foreground">Checking authentication...</p>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (!user) {
    return null; // Will be redirected by the useEffect
  }

  if (error) {
    return (
      <div className="container max-w-2xl mx-auto py-8">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-red-500">
              <XCircle className="h-6 w-6" />
              Error
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-muted-foreground">{error}</p>
            <Button
              className="mt-6"
              onClick={() => navigate('/validate')}
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back to Validation
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (!job) {
    return (
      <div className="container max-w-2xl mx-auto py-8">
        <Card>
          <CardContent className="py-12">
            <div className="flex flex-col items-center justify-center">
              <Clock className="h-8 w-8 animate-spin text-muted-foreground" />
              <p className="mt-4 text-muted-foreground">Loading validation job...</p>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  const estimatedTimeLeft = job.createdAt
    ? formatDistanceToNow(addMinutes(job.createdAt, Math.ceil(job.totalEmails / 100)))
    : formatDistanceToNow(addMinutes(new Date(), Math.ceil(job.totalEmails / 100)));

  return (
    <div className="container max-w-2xl mx-auto py-8">
      <Card className="overflow-hidden">
        <CardHeader className="border-b bg-muted/10">
          <CardTitle className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Button 
                variant="ghost" 
                size="icon"
                onClick={() => navigate('/validate')}
              >
                <ArrowLeft className="h-4 w-4" />
              </Button>
              <span className="truncate">{job.fileName}</span>
            </div>
            {isRealtimeConnected ? (
              <div className="flex items-center text-sm text-green-500 bg-green-50 px-2 py-1 rounded-md">
                <Wifi className="h-4 w-4 mr-1" />
                Live Updates
              </div>
            ) : (
              <div className="flex items-center text-sm text-yellow-500 bg-yellow-50 px-2 py-1 rounded-md">
                <WifiOff className="h-4 w-4 mr-1" />
                Polling Updates
              </div>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          <div className="flex flex-col space-y-6">
            {renderProgress()}
            
            {/* Time Information */}
            <div className="flex flex-col items-center space-y-1 pt-2">
              {job.status !== 'completed' && (
                <p className="text-sm font-medium">
                  Estimated completion in {estimatedTimeLeft}
                </p>
              )}
              <p className="text-xs text-muted-foreground">
                Started {formatDistanceToNow(job.createdAt, { addSuffix: true })}
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
