import { BrowserRouter as Router, Routes, Route, Outlet, useLocation, Navigate } from 'react-router-dom';
import { Toaster } from '@/components/ui/toaster';
import { Header } from '@/components/layout/Header';
import { Sidebar } from '@/components/layout/Sidebar';
import { Footer } from '@/components/layout/Footer';
import { LoginForm } from '@/components/auth/LoginForm';
import { RegisterForm } from '@/components/auth/RegisterForm';
import { ForgotPasswordForm } from '@/components/auth/ForgotPasswordForm';
import { ResetPasswordForm } from '@/components/auth/ResetPasswordForm';
import { Stats } from '@/components/dashboard/Stats';
import { UploadForm } from '@/components/validation/UploadForm';
import { ValidationHistory } from '@/components/history/ValidationHistory';
import { ValidationDetails } from '@/components/history/ValidationDetails';
import { CreditPackages } from '@/components/credits/CreditPackages';
import { PaymentHistory } from '@/pages/payment-history';
import { PaymentInfo } from '@/components/credits/PaymentInfo';
import { SettingsForm } from '@/components/settings/SettingsForm';
import { ApiDashboard } from '@/components/api/ApiDashboard';
import { ThemeProvider } from '@/components/theme-provider';
import { ProtectedRoute } from '@/components/auth/ProtectedRoute';
import { ErrorBoundary } from 'react-error-boundary';
import { AdminLoginForm } from './components/admin/auth/AdminLoginForm';
import { AdminRoute } from './components/admin/AdminRoute';
import { AdminLayout } from './components/admin/AdminLayout';
import { AdminDashboard } from '@/components/admin/Dashboard';
import { UserManagement } from '@/components/admin/users/UserManagement';
import { CreditPackageManagement } from '@/components/admin/packages/CreditPackageManagement';
import { PaymentGatewayManagement } from '@/components/admin/payments/PaymentGatewayManagement';
import { AdminSettings } from './components/admin/settings/AdminSettings';
import { AdminValidationHistory } from '@/components/admin/validation/AdminValidationHistory';
import { InvoiceManagement } from '@/components/admin/invoices/InvoiceManagement';
import { CookieConsent } from '@/components/CookieConsent';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from '@/contexts/AppContext';
import { supabase } from '@/lib/supabase';
import { Session } from '@supabase/supabase-js';
import { ValidationResults } from '@/components/validation/ValidationResults';
import APIPage from '@/pages/api';
import { AuthSlider } from '@/components/auth/AuthSlider';
import { AuthLayout } from '@/components/auth/AuthLayout';
import { AuthContainer } from '@/components/auth/AuthContainer';
import { AuthCallback } from '@/components/auth/AuthCallback';
import { EmailAssistant } from '@/pages/email-assistant';
import EmailHistoryPage from '@/pages/email-history';
import EmailMarketingPage from '@/pages/email-marketing';
import { CreateTicket } from '@/components/support/CreateTicket';
import { TicketList } from '@/components/support/TicketList';
import { TicketDetails } from '@/components/support/TicketDetails';
import { AdminSupportPage } from '@/pages/admin/support';
import { AdminTicketList } from '@/components/admin/support/AdminTicketList';
import { AdminTicketDetails } from '@/components/admin/support/AdminTicketDetails';
import GeminiSettings from '@/pages/admin/gemini-settings';
import ProviderSettings from '@/components/admin/providers/ProviderSettings';
import { MaintenancePage } from '@/components/maintenance/MaintenancePage';
import { checkMaintenanceMode } from '@/middleware/maintenanceMode';
import { OnboardingTour } from '@/components/onboarding/OnboardingTour';
import { ActiveJobs } from '@/components/validation/ActiveJobs';
import { ProcessingPage } from '@/components/validation/ProcessingPage';
import { SubscriptionManagement } from '@/components/subscription/SubscriptionManagement';

function AppLayout({ children }: { children: React.ReactNode }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const { userProfile } = useContext(AppContext);

  // Function to determine footer spacing based on route
  const getFooterSpacing = () => {
    const path = location.pathname;
    
    // Add specific spacing for different routes
    switch (true) {
      case path === '/dashboard':
        return 'mt-16';
      case path === '/validate':
      case path.startsWith('/validation/'):
        return 'mt-20';
      case path === '/credits':
      case path === '/pricing':
        return 'mt-24';
      case path === '/settings':
        return 'mt-12';
      default:
        return 'mt-16'; // Default spacing
    }
  };

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <div className="flex min-h-screen flex-col">
        <Header onMenuClick={() => setIsSidebarOpen(!isSidebarOpen)} />
        <div className="flex flex-1">
          <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
          <main className="flex-1 w-full transition-all duration-200 ease-in-out md:pl-64">
            <div className="container py-4 px-4">
              {userProfile && <OnboardingTour />}
              {children}
            </div>
          </main>
        </div>
        <div className={`md:pl-64 ${getFooterSpacing()}`}>
          <Footer />
        </div>
      </div>
    </ErrorBoundary>
  );
}

async function checkAdminStatus(userId: string | undefined) {
  if (!userId) return false;

  try {
    const { data, error } = await supabase
      .from('user_profiles')
      .select('is_admin')
      .eq('id', userId)
      .single();

    if (error) throw error;
    return data?.is_admin || false;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
}

function MaintenanceWrapper() {
  const { isAdmin, isMaintenanceMode } = useContext(AppContext);
  const location = useLocation();
  const isAuthRoute = ['/login', '/register', '/forgot-password', '/reset-password', '/auth/callback'].includes(location.pathname);

  // Show maintenance page for non-admin users and non-auth routes
  if (isMaintenanceMode && !isAdmin && !isAuthRoute) {
    return <MaintenancePage />;
  }

  return <Outlet />;
}

function App() {
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [adminAuthenticated, setAdminAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState<{ created_at: string; completed_onboarding: boolean } | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      setSession(session);
      if (session) {
        const [adminStatus, profile] = await Promise.all([
          checkAdminStatus(session.user?.id),
          supabase
            .from('user_profiles')
            .select('created_at, completed_onboarding')
            .eq('id', session.user.id)
            .single()
            .then(({ data }) => data)
        ]);
        
        setIsAdmin(adminStatus);
        setUserProfile(profile);
        
        if (adminStatus) {
          setAdminAuthenticated(true);
        }
      }
      checkMaintenance();
      setIsLoading(false);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event: string, session: Session | null) => {
      setSession(session);
      if (session) {
        checkAdminStatus(session.user?.id).then(setIsAdmin);
      } else {
        setIsAdmin(false);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const checkMaintenance = async () => {
    const isInMaintenance = await checkMaintenanceMode();
    setIsMaintenanceMode(isInMaintenance);
  };

  const adminAuth = {
    isAuthenticated: adminAuthenticated,
    login: (session: Session) => {
      setSession(session);
      setAdminAuthenticated(true);
      checkAdminStatus(session.user?.id).then(setIsAdmin);
    },
    logout: () => {
      setAdminAuthenticated(false);
      setIsAdmin(false);
    }
  };

  const contextValue = {
    session,
    isAdmin,
    isMaintenanceMode,
    setIsMaintenanceMode,
    adminAuth,
    userProfile
  };

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <AppContext.Provider value={contextValue}>
        <Router>
          <Routes>
            {/* Auth Routes */}
            <Route path="/login" element={<AuthContainer><LoginForm /></AuthContainer>} />
            <Route path="/register" element={<AuthContainer><RegisterForm /></AuthContainer>} />
            <Route path="/forgot-password" element={<AuthContainer><ForgotPasswordForm /></AuthContainer>} />
            <Route path="/reset-password" element={<AuthContainer><ResetPasswordForm /></AuthContainer>} />
            <Route path="/auth/callback" element={<AuthContainer><AuthCallback /></AuthContainer>} />
            <Route path="/admin/login" element={<AuthContainer><AdminLoginForm /></AuthContainer>} />

            {/* Admin Routes */}
            <Route path="/admin/*" element={
              <AdminRoute>
                <AdminLayout>
                  <Routes>
                    <Route index element={<AdminDashboard />} />
                    <Route path="users" element={<UserManagement />} />
                    <Route path="packages" element={<CreditPackageManagement />} />
                    <Route path="payments" element={<PaymentGatewayManagement />} />
                    <Route path="support" element={<AdminSupportPage />} />
                    <Route path="support/tickets" element={<AdminTicketList />} />
                    <Route path="support/tickets/:ticketId" element={<AdminTicketDetails />} />
                    <Route path="validations" element={<AdminValidationHistory />} />
                    <Route path="invoices" element={<InvoiceManagement />} />
                    <Route path="analytics" element={<AdminDashboard />} />
                    <Route path="providers" element={<ProviderSettings />} />
                    <Route path="settings" element={<AdminSettings />} />
                    <Route path="gemini-settings" element={<GeminiSettings />} />
                  </Routes>
                </AdminLayout>
              </AdminRoute>
            } />

            {/* User Routes with Maintenance Check */}
            <Route element={<MaintenanceWrapper />}>
              <Route path="/" element={<ProtectedRoute><AppLayout><Outlet /></AppLayout></ProtectedRoute>}>
                <Route index element={<Navigate to="/dashboard" replace />} />
                <Route path="dashboard" element={<Stats />} />
                <Route path="validate" element={<UploadForm />} />
                <Route path="email-assistant" element={<EmailAssistant />} />
                <Route path="email-history" element={<EmailHistoryPage />} />
                <Route path="history" element={<ValidationHistory />} />
                <Route path="history/:id" element={<ValidationDetails />} />
                <Route path="validation/results" element={<ValidationResults />} />
                <Route path="api" element={<APIPage />} />
                <Route path="credits" element={
                  <div className="space-y-4 pt-2">
                    <h1 className="hidden text-3xl font-bold tracking-tight md:block">Purchase Credits</h1>
                    <CreditPackages />
                    <h2 className="text-2xl font-bold tracking-tight">Payment Information</h2>
                    <PaymentInfo />
                  </div>
                } />
                <Route path="payment-history" element={
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">Payment History</h1>
                    </div>
                    <PaymentHistory />
                  </div>
                } />
                <Route path="settings" element={
                  <div className="space-y-4 pt-2">
                    <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">Settings</h1>
                    <SettingsForm />
                  </div>
                } />
                <Route path="email-marketing" element={<EmailMarketingPage />} />
                <Route path="support/tickets" element={<TicketList />} />
                <Route path="support/tickets/new" element={<CreateTicket />} />
                <Route path="support/tickets/:ticketId" element={<TicketDetails />} />
                <Route path="active-jobs" element={<ActiveJobs />} />
                <Route path="validation/:id/processing" element={<ProcessingPage />} />
                <Route path="subscription" element={<SubscriptionManagement />} />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
          <Toaster />
          <CookieConsent />
        </Router>
      </AppContext.Provider>
    </ErrorBoundary>
  );
}

export default App;