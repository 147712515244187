import { useEffect, useState } from 'react';
import { useUser } from '@/lib/hooks/useUser';
import { supabase } from '@/lib/supabase';
import { Subscription } from '@/types/subscription';
import { useToast } from '@/components/ui/use-toast';

export function useSubscription() {
  const { user } = useUser();
  const { toast } = useToast();
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const loadSubscription = async () => {
      try {
        if (!user?.id) return;

        const { data, error } = await supabase
          .from('user_subscriptions')
          .select(`
            *,
            package:package_id (
              name,
              credits_per_month,
              price
            )
          `)
          .eq('user_id', user.id)
          .not('status', 'eq', 'expired')
          .order('created_at', { ascending: false })
          .limit(1)
          .single();

        if (error && error.code !== 'PGRST116') {
          console.error('Error fetching subscription:', error);
          setError(new Error('Failed to load subscription details'));
          toast({
            title: 'Error',
            description: 'Failed to load subscription details',
            variant: 'destructive',
          });
          return;
        }

        const { data: available_packages } = await supabase
          .from('packages')
          .select('*')
          .eq('type', 'subscription')
          .eq('is_active', true)
          .order('price');

        setSubscription(data ? {
          ...data,
          available_packages: available_packages || []
        } : null);
      } catch (error) {
        console.error('Error:', error);
        setError(error instanceof Error ? error : new Error('Unknown error'));
      } finally {
        setLoading(false);
      }
    };

    loadSubscription();
  }, [user, toast]);

  return { subscription, loading, error };
} 