import { supabase } from './supabase';
import { validationService } from './validationService';
import axios from 'axios';
import type { 
  ValidationResult, 
  ValidationHistoryRecord, 
  ValidationStatus,
  ValidationFilters,
  ValidationIssues,
  ProviderName,
  EdgeFunctionValidationResult
} from '@/types/providers';
import { SupabaseClient } from '@supabase/supabase-js';

interface ValidationHistoryResponse {
  results: ValidationHistoryRecord[];
  total: number;
  pages: number;
}

interface DeductCreditsResult {
  success: boolean;
  error?: string;
  deducted: number;
  remaining: number;
}

export async function validateEmails(
  emails: string[], 
  fileName?: string
): Promise<ValidationResult> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    console.log('Starting validation for user:', user.id);

    // First create a validation history record
    const { data: validationRecord, error: recordError } = await supabase
      .from('validation_history')
      .insert({
        user_id: user.id,
        file_name: fileName || `Validation ${new Date().toISOString()}`,
        total_emails: emails.length,
        status: 'processing',
        progress_percentage: 0,
        estimated_completion_time: new Date(Date.now() + (emails.length * 1000)).toISOString()
      })
      .select()
      .single();

    if (recordError || !validationRecord) {
      console.error('Failed to create validation record:', recordError);
      throw new Error('Failed to create validation record');
    }

    console.log('Created validation record:', validationRecord.id);

    // Return immediately with the validation ID
    const initialResult: ValidationResult = {
      id: validationRecord.id,
      date: new Date(),
      status: 'pending' as const,
      totalEmails: emails.length,
      creditsUsed: emails.length,
      valid: [],
      invalid: [],
      risky: [],
      disposable: [],
      spamTraps: [],
      confidence: 0,
      provider: 'internal',
      response_time: 0,
      validation_result: '[]'
    };

    // Get the access token
    const session = await supabase.auth.getSession();
    const accessToken = session.data.session?.access_token;
    if (!accessToken) {
      throw new Error('No access token available');
    }

    console.log('Starting edge function validation');

    // Start validation in background
    fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/validate-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        emails,
        userId: user.id,
        validationId: validationRecord.id,
        fileName: fileName || validationRecord.file_name,
        providerType: 'gmail' // Default to Gmail provider
      })
    })
    .then(async response => {
      if (!response.ok) {
        return response.text().then(text => {
          console.error('Edge function error:', text);
          throw new Error(`Edge function failed: ${text}`);
        });
      }
      const data = await response.json();
      console.log('Edge function response:', data);

      // Wait for the validation to complete
      const maxAttempts = 30;
      const delayMs = 500;
      
      for (let i = 0; i < maxAttempts; i++) {
        const { data: record, error } = await supabase
          .from('validation_history')
          .select('*')
          .eq('id', validationRecord.id)
          .single();

        if (error) {
          console.error('Error fetching validation record:', error);
          continue;
        }

        console.log('Checking validation status:', {
          attempt: i + 1,
          status: record.status,
          progress: record.progress_percentage
        });

        if (record.status === 'completed') {
          return {
            id: record.id,
            date: new Date(record.created_at),
            status: record.status,
            totalEmails: record.total_emails,
            creditsUsed: record.credits_used,
            valid: record.valid_emails || [],
            invalid: record.invalid_emails || [],
            risky: record.risky_emails || [],
            disposable: record.disposable_emails || [],
            spamTraps: record.spam_trap_emails || [],
            confidence: record.confidence,
            provider: record.provider as ProviderName,
            response_time: record.response_time,
            validation_result: record.validation_result,
            fileName: record.file_name
          };
        } else if (record.status === 'failed') {
          throw new Error(record.error || 'Validation failed');
        }

        // Wait before next attempt
        await new Promise(resolve => setTimeout(resolve, delayMs));
      }

      throw new Error('Validation timed out waiting for completion');
    })
    .catch(error => {
      console.error('Error during validation:', error);
      throw error;
    });

    return initialResult;

  } catch (error) {
    console.error('Validation error:', error);
    throw error;
  }
}

export async function validateSingleEmail(email: string): Promise<ValidationResult> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    // Create validation record first
    const { data: validationRecord, error: recordError } = await supabase
      .from('validation_history')
      .insert({
        user_id: user.id,
        file_name: `Single Email: ${email}`,
        total_emails: 1,
        status: 'processing',
        progress_percentage: 0
      })
      .select()
      .single();

    if (recordError || !validationRecord) {
      console.error('Failed to create validation record:', recordError);
      throw new Error('Failed to create validation record');
    }

    // Get the access token
    const session = await supabase.auth.getSession();
    const accessToken = session.data.session?.access_token;
    if (!accessToken) {
      throw new Error('No access token available');
    }

    // Call edge function
    const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/validate-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        emails: [email],
        userId: user.id,
        validationId: validationRecord.id,
        fileName: `Single Email: ${email}`,
        providerType: 'gmail',
        isSingleEmail: true
      })
    });

    if (!response.ok) {
      const text = await response.text();
      console.error('Edge function error:', text);
      throw new Error(`Edge function failed: ${text}`);
    }

    const data = await response.json();
    console.log('Single email validation response:', data);

    // Wait for the validation to complete (with shorter timeout)
    const maxAttempts = 10;
    const delayMs = 500;
    
    for (let i = 0; i < maxAttempts; i++) {
      const { data: record, error } = await supabase
        .from('validation_history')
        .select('*')
        .eq('id', validationRecord.id)
        .single();

      if (error) {
        console.error('Error fetching validation record:', error);
        continue;
      }

      console.log('Checking single validation status:', {
        attempt: i + 1,
        status: record.status,
        progress: record.progress_percentage
      });

      if (record.status === 'completed') {
        return {
          id: record.id,
          date: new Date(record.created_at),
          status: record.status,
          totalEmails: 1,
          creditsUsed: 1,
          valid: record.valid_emails || [],
          invalid: record.invalid_emails || [],
          risky: record.risky_emails || [],
          disposable: record.disposable_emails || [],
          spamTraps: record.spam_trap_emails || [],
          confidence: record.confidence || 0,
          provider: record.provider as ProviderName,
          response_time: record.response_time || 0,
          validation_result: record.validation_result || '',
          metadata: {
            fileName: record.file_name
          }
        };
      } else if (record.status === 'failed') {
        throw new Error(record.error || 'Validation failed');
      }

      // Wait before next attempt
      await new Promise(resolve => setTimeout(resolve, delayMs));
    }

    throw new Error('Validation timed out waiting for completion');
  } catch (error) {
    console.error('Single email validation error:', error);
    throw error;
  }
}

export async function getUserCredits(): Promise<number> {
  try {
    // Get the current user first
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    // Get user's credits
    const { data: userProfile, error } = await supabase
      .from('user_profiles')
      .select('credits')
      .eq('id', user.id)
      .single();

    if (error) throw error;

    const credits = userProfile?.credits || 0;
    
    // Update localStorage and trigger storage event
    localStorage.setItem('userCredits', credits.toString());
    // Use a custom event instead of StorageEvent for better cross-component communication
    window.dispatchEvent(new CustomEvent('creditUpdate', { 
      detail: { credits }
    }));
    
    return credits;
  } catch (error) {
    console.error('Failed to get user credits:', error);
    return 0;
  }
};

export async function getValidationHistory(filters: ValidationFilters): Promise<ValidationHistoryResponse> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    let query = supabase
      .from('validation_history')
      .select('*', { count: 'exact' })
      .order('created_at', { ascending: false });

    // Add user filter for non-admin users
    if (!filters.isAdmin) {
      query = query.eq('user_id', user.id);
    }

    // Apply filters
    if (filters.status && filters.status !== 'all') {
      query = query.eq('status', filters.status);
    }

    if (filters.search) {
      query = query.ilike('file_name', `%${filters.search}%`);
    }

    // Apply sorting
    if (filters.sort) {
      switch (filters.sort) {
        case 'oldest':
          query = query.order('created_at', { ascending: true });
          break;
        case 'largest':
          query = query.order('total_emails', { ascending: false });
          break;
        case 'smallest':
          query = query.order('total_emails', { ascending: true });
          break;
        default: // 'newest' is default
          query = query.order('created_at', { ascending: false });
      }
    }

    // Apply pagination
    const pageSize = filters.pageSize || 20;
    const page = filters.page || 1;
    const start = (page - 1) * pageSize;
    
    const { data, error, count } = await query.range(start, start + pageSize - 1);

    if (error) throw error;

    // Transform data to match ValidationHistoryRecord type
    const results = data.map(record => ({
      id: record.id,
      user_id: record.user_id,
      total_emails: record.total_emails || 0,
      valid_emails: record.valid_emails || [],
      invalid_emails: record.invalid_emails || [],
      risky_emails: record.risky_emails || [],
      disposable_emails: record.disposable_emails || [],
      spam_trap_emails: record.spam_trap_emails || [],
      file_name: record.file_name,
      credits_used: record.credits_used || 0,
      status: record.status,
      confidence: record.confidence || 0,
      provider: record.provider || 'unknown',
      issues: record.issues,
      response_time: record.response_time || 0,
      validation_result: record.validation_result || '',
      created_at: record.created_at,
      updated_at: record.updated_at
    }));

    return {
      results,
      total: count || 0,
      pages: Math.ceil((count || 0) / pageSize)
    };

  } catch (error) {
    console.error('Error fetching validation history:', error);
    throw error;
  }
}

export async function downloadValidationReport(id: string): Promise<Blob> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    const { data, error } = await supabase
      .from('validation_history')
      .select('*')
      .eq('id', id)
      .eq('user_id', user.id)
      .single();

    if (error) throw error;
    if (!data) throw new Error('Validation not found');

    const record: ValidationHistoryRecord = {
      id: data.id,
      user_id: data.user_id,
      email: data.email,
      total_emails: data.total_emails,
      valid_emails: data.valid_emails || [],
      invalid_emails: data.invalid_emails || [],
      risky_emails: data.risky_emails || [],
      disposable_emails: data.disposable_emails || [],
      spam_trap_emails: data.spam_trap_emails || [],
      file_name: data.file_name,
      credits_used: data.credits_used,
      status: data.status as ValidationStatus,
      confidence: data.confidence,
      provider: data.provider,
      issues: data.issues,
      response_time: data.response_time,
      validation_result: data.validation_result,
      created_at: data.created_at,
      updated_at: data.updated_at
    };

    const rows = [
      ['Email', 'Status'],
      ...record.valid_emails.map(email => [email, 'Valid']),
      ...record.invalid_emails.map(email => [email, 'Invalid']),
      ...record.risky_emails.map(email => [email, 'Risky']),
      ...record.disposable_emails.map(email => [email, 'Disposable']),
      ...record.spam_trap_emails.map(email => [email, 'Spam Trap'])
    ];

    const csv = rows.map(row => row.join(',')).join('\n');
    return new Blob([csv], { type: 'text/csv' });
  } catch (error) {
    console.error('Error downloading validation report:', error);
    throw error;
  }
}