export class NOWPaymentsService {
  private apiKey: string;
  private baseUrl = 'https://api.nowpayments.io/v1';

  constructor() {
    this.apiKey = import.meta.env.VITE_NOWPAYMENTS_API_KEY;
  }

  // Helper method to format currency with network
  private formatCurrency(currency: string, network?: string): string {
    if (!network || network === 'bitcoin') return currency;
    // For USDT and USDC, use the network code directly
    if (network.startsWith('usdt') || network.startsWith('usdc')) {
      return network;
    }
    return currency;
  }

  async createPayment(params: {
    price_amount: number;
    package_id: string;
    user_id: string;
    currency: string;
    network?: string;
  }) {
    try {
      // Format the currency with network
      const formattedCurrency = this.formatCurrency(params.currency, params.network);
      console.log('Creating payment with currency:', formattedCurrency);

      const invoiceResponse = await fetch(`${this.baseUrl}/invoice`, {
        method: 'POST',
        headers: {
          'x-api-key': this.apiKey,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          price_amount: params.price_amount,
          price_currency: 'usd',
          pay_currency: formattedCurrency,
          order_id: `${params.user_id}_${params.package_id}_${Date.now()}`,
          order_description: 'Email Validation Credits Purchase',
          success_url: `${window.location.origin}/dashboard?payment=success`,
          cancel_url: `${window.location.origin}/dashboard?payment=cancelled`,
          ipn_callback_url: `${window.location.origin}/api/crypto-webhook`,
          is_fixed_rate: true,
        }),
      });

      if (!invoiceResponse.ok) {
        const error = await invoiceResponse.json();
        console.error('NOWPayments Invoice API error:', error);
        throw new Error(error.message || 'Failed to create invoice');
      }

      const invoiceData = await invoiceResponse.json();
      console.log('NOWPayments invoice response:', invoiceData);

      return {
        payment_id: invoiceData.id,
        payment_url: invoiceData.invoice_url,
        payment_status: 'waiting',
        price_amount: params.price_amount,
        pay_amount: invoiceData.pay_amount,
        order_id: invoiceData.order_id
      };
    } catch (error) {
      console.error('NOWPayments error:', error);
      throw error;
    }
  }
} 