import { useState, useEffect } from 'react';
import { Bitcoin, Check } from 'lucide-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { CreditPackage } from '@/lib/types';
import { VisaIcon, MastercardIcon, ETHIcon } from '@/components/ui/icons/payment-icons';
import { getCreditPackages } from '@/lib/admin';
import { useToast } from '@/components/ui/use-toast';
import { LemonSqueezyOverlay } from '@/components/payment/LemonSqueezyOverlay';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { useUser } from '@/lib/hooks/useUser';
import { NOWPaymentsService } from '@/lib/services/NOWPaymentsService';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const SUBSCRIPTION_PLANS = [
  {
    id: 'plan1',
    name: 'Basic Plan',
    price: 29.99,
    variantId: '688194',
    description: 'Perfect for small businesses and startups',
    features: [
      'Perfect for small businesses and startups',
      '5,000 email validations per month',
      'Real-time validation',
      'Full API access',
      'Advanced validation rules',
      'Priority support',
      'Detailed validation reports'
    ]
  },
  {
    id: 'plan2',
    name: 'Pro Plan',
    price: 79.99,
    variantId: '688195',
    description: 'Most popular choice for growing companies',
    features: [
      'Most popular choice for growing companies',
      '15,000 email validations per month',
      'Real-time validation',
      'Full API access',
      'Advanced validation rules',
      'Priority support',
      'Detailed validation reports'
    ]
  },
  {
    id: 'plan3',
    name: 'Enterprise Plan',
    price: 199.99,
    variantId: '688368',
    description: 'Ideal for large-scale email validation needs',
    features: [
      'Ideal for large-scale email validation needs',
      '50,000 email validations per month',
      'Real-time validation',
      'Premium API access',
      'Custom validation rules',
      '24/7 priority support',
      'Advanced analytics'
    ]
  }
];

// Add interface for subscription plan
interface SubscriptionPlan {
  id: string;
  name: string;
  price: number;
  variant_id: string;
  description: string;
  features: string[];
}

// Update the CRYPTO_CURRENCIES constant with more detailed information
const CRYPTO_CURRENCIES = [
  { 
    value: 'btc', 
    label: 'Bitcoin (BTC)', 
    icon: Bitcoin, 
    networks: ['bitcoin'] 
  },
  { 
    value: 'eth', 
    label: 'Ethereum (ETH)', 
    icon: ETHIcon, 
    networks: ['ethereum'] 
  },
  { 
    value: 'usdt', 
    label: 'Tether (USDT)', 
    icon: Bitcoin, 
    networks: [
      { value: 'usdterc20', label: 'Ethereum (ERC20)' },
      { value: 'usdttrc20', label: 'Tron (TRC20)' },
      { value: 'usdtbsc', label: 'BSC (BEP20)' }
    ] 
  },
  { 
    value: 'usdc', 
    label: 'USD Coin (USDC)', 
    icon: Bitcoin, 
    networks: [
      { value: 'usdcerc20', label: 'Ethereum (ERC20)' },
      { value: 'usdcbsc', label: 'BSC (BEP20)' }
    ] 
  },
] as const;

export function CreditPackages() {
  const [packages, setPackages] = useState<CreditPackage[]>([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>([]);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();
  const { user } = useUser();
  const [selectedCurrency, setSelectedCurrency] = useState<string>('btc');
  const [isCurrencyDialogOpen, setIsCurrencyDialogOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<CreditPackage | null>(null);
  const [selectedNetwork, setSelectedNetwork] = useState<string>('');

  const mapPackagesWithFeatures = (packages: any[]) => {
    return packages.map(pkg => {
      // Ensure we have a valid variant ID
      const variantId = pkg.lemon_variant_id && pkg.lemon_variant_id.match(/^[0-9]+$/) 
        ? pkg.lemon_variant_id 
        : '624631'; // Default to Starter package if invalid

      return {
        ...pkg,
        features: getFeaturesByPackageType(pkg.name),
        lemon_variant_id: variantId
      };
    });
  };

  const getFeaturesByPackageType = (packageName: string) => {
    const name = packageName.toLowerCase();
    if (name.includes('starter')) {
      return [
        'Perfect for small businesses and startups',
        '5,000 email validations',
        'Real-time validation',
        'Full API access',
        'Advanced validation rules',
        'Priority support',
        'Detailed validation reports'
      ];
    } else if (name.includes('pro')) {
      return [
        'Most popular choice for growing companies',
        '15,000 email validations',
        'Real-time validation',
        'Full API access',
        'Advanced validation rules',
        'Priority support',
        'Detailed validation reports'
      ];
    } else if (name.includes('enterprise')) {
      return [
        'Ideal for large-scale email validation needs',
        '50,000 email validations',
        'Real-time validation',
        'Premium API access',
        'Custom validation rules',
        '24/7 priority support',
        'Advanced analytics'
      ];
    }
    return [];
  };

  const getFeaturesByPlanType = (planName: string) => {
    const name = planName.toLowerCase();
    if (name.includes('basic')) {
      return [
        'Perfect for small businesses and startups',
        '5,000 email validations per month',
        'Real-time validation',
        'Full API access',
        'Advanced validation rules',
        'Priority support',
        'Detailed validation reports'
      ];
    } else if (name.includes('pro')) {
      return [
        'Most popular choice for growing companies',
        '15,000 email validations per month',
        'Real-time validation',
        'Full API access',
        'Advanced validation rules',
        'Priority support',
        'Detailed validation reports'
      ];
    } else if (name.includes('expert')) {
      return [
        'Ideal for large-scale email validation needs',
        '50,000 email validations per month',
        'Real-time validation',
        'Premium API access',
        'Custom validation rules',
        '24/7 priority support',
        'Advanced analytics',
        'Dedicated account manager'
      ];
    }
    return [];
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        // Load one-time packages
        const { data: packagesData, error: packagesError } = await supabase
          .from('packages')
          .select('*')
          .eq('type', 'one_time')
          .eq('is_active', true);

        if (packagesError) throw packagesError;
        const mappedPackages = mapPackagesWithFeatures(packagesData);
        setPackages(mappedPackages);

        // Load subscription plans
        const { data: plans, error: plansError } = await supabase
          .from('packages')
          .select('*')
          .eq('type', 'subscription')
          .eq('is_active', true);

        if (plansError) throw plansError;
        const mappedPlans = plans.map(plan => ({
          ...plan,
          features: getFeaturesByPlanType(plan.name)
        }));

        setSubscriptionPlans(mappedPlans);
      } catch (error) {
        console.error('Error loading data:', error);
        toast({
          title: 'Error',
          description: 'Failed to load packages and plans',
          variant: 'destructive',
        });
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handlePaymentSuccess = () => {
    window.location.reload();
  };

  const handleCryptoPayment = async (pkg: CreditPackage) => {
    setSelectedPackage(pkg);
    setIsCurrencyDialogOpen(true);
  };

  const handleCryptoPaymentConfirm = async () => {
    try {
      if (!user || !selectedPackage || !selectedNetwork) {
        toast({
          title: "Error",
          description: "Please complete all required fields",
          variant: "destructive"
        });
        return;
      }

      // Create payment with NOWPayments
      const nowPayments = new NOWPaymentsService();
      const paymentData = await nowPayments.createPayment({
        price_amount: selectedPackage.price,
        package_id: selectedPackage.id,
        user_id: user.id,
        currency: selectedCurrency,
        network: selectedNetwork
      });

      if (!paymentData.payment_url) {
        throw new Error('No payment URL received');
      }

      // Create payment record in database
      const { error: dbError } = await supabase
        .from('payments')
        .insert({
          user_id: user.id,
          package_id: selectedPackage.id,
          amount: selectedPackage.price,
          currency: selectedCurrency,
          status: 'pending',
          payment_method: 'crypto',
          provider: 'nowpayments',
          provider_payment_id: paymentData.payment_id,
          credits: selectedPackage.credits,
          is_subscription: false,
          network: selectedNetwork
        });

      if (dbError) {
        console.error('Database error:', dbError);
        throw new Error('Failed to create payment record');
      }

      // Close dialog and open payment URL in new tab
      setIsCurrencyDialogOpen(false);
      window.open(paymentData.payment_url, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Payment error:', error);
      toast({
        title: "Payment Error",
        description: error.message || "Failed to process payment",
        variant: "destructive"
      });
    }
  };

  if (loading) {
    return <div>Loading packages...</div>;
  }

  return (
    <>
      <div className="space-y-12">
        {/* Subscription Plans Section */}
        <div>
          <div className="text-center px-4 sm:px-0 mb-6">
            <h2 className="text-2xl sm:text-3xl font-bold tracking-tight">Monthly Subscription Plans</h2>
            <p className="text-muted-foreground mt-2 text-sm sm:text-base">
              Choose a subscription plan for recurring email validations
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 px-4 sm:px-0">
            {subscriptionPlans.map((plan) => (
              <Card 
                key={plan.id} 
                className="group relative flex flex-col border-primary/20 transition-all duration-300
                  hover:border-primary hover:shadow-[0_0_1rem_rgba(var(--primary),.15)]"
              >
                <CardHeader className="space-y-2">
                  <CardTitle className="text-lg sm:text-xl group-hover:text-primary transition-colors">
                    {plan.name}
                  </CardTitle>
                  <CardDescription className="text-sm">{plan.description}</CardDescription>
                </CardHeader>
                <CardContent className="flex-1 space-y-4">
                  <div className="text-center">
                    <div className="text-3xl sm:text-4xl font-bold group-hover:text-primary transition-colors">
                      ${plan.price}
                      <span className="text-base sm:text-lg text-muted-foreground font-normal ml-1">
                        /month
                      </span>
                    </div>
                  </div>
                  <ul className="space-y-2">
                    {plan.features.map((feature: string, index: number) => (
                      <li key={index} className="flex items-center text-sm">
                        <Check className="h-4 w-4 text-primary mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter className="flex flex-col space-y-4 pt-4">
                  <LemonSqueezyOverlay
                    variantId={plan.variant_id || ''}
                    packageId={plan.id}
                    onSuccess={handlePaymentSuccess}
                    buttonLabel="Subscribe Now"
                    buttonClassName="w-full"
                  />
                  <div className="flex justify-center space-x-3">
                    <VisaIcon className="h-5 w-5 sm:h-6 sm:w-6 dark:text-white" />
                    <MastercardIcon className="h-5 w-5 sm:h-6 sm:w-6 dark:text-white" />
                  </div>
                </CardFooter>
              </Card>
            ))}
          </div>
        </div>

        {/* One-time Credit Packages Section */}
        <div>
          <div className="text-center px-4 sm:px-0 mb-6">
            <h2 className="text-2xl sm:text-3xl font-bold tracking-tight">One-time Credit Packages</h2>
            <p className="text-muted-foreground mt-2 text-sm sm:text-base">
              Purchase credits as needed without any recurring commitment
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 px-4 sm:px-0">
            {packages.map((pkg) => (
              <Card 
                key={pkg.id} 
                className="group relative flex flex-col border-primary/20 transition-all duration-300
                  hover:border-primary hover:shadow-[0_0_1rem_rgba(var(--primary),.15)]"
              >
                <CardHeader className="space-y-2">
                  <CardTitle className="text-lg sm:text-xl group-hover:text-primary transition-colors">
                    {pkg.name}
                  </CardTitle>
                  <CardDescription className="text-sm">
                    Perfect for {pkg.name.toLowerCase()} businesses and projects
                  </CardDescription>
                </CardHeader>
                <CardContent className="flex-1 space-y-4">
                  <div className="text-center">
                    <div className="text-3xl sm:text-4xl font-bold group-hover:text-primary transition-colors">
                      ${pkg.price}
                      <span className="text-base sm:text-lg text-muted-foreground font-normal ml-1">
                        one-time
                      </span>
                    </div>
                    <div className="text-sm text-muted-foreground mt-1">
                      {pkg.credits.toLocaleString()} Credits
                    </div>
                  </div>
                  <ul className="space-y-2">
                    <li className="flex items-center text-sm">
                      <Check className="h-4 w-4 text-primary mr-2" />
                      {pkg.credits.toLocaleString()} email validations
                    </li>
                    <li className="flex items-center text-sm">
                      <Check className="h-4 w-4 text-primary mr-2" />
                      Real-time validation
                    </li>
                    <li className="flex items-center text-sm">
                      <Check className="h-4 w-4 text-primary mr-2" />
                      Full API access
                    </li>
                    <li className="flex items-center text-sm">
                      <Check className="h-4 w-4 text-primary mr-2" />
                      Never expires
                    </li>
                  </ul>
                </CardContent>
                <CardFooter className="flex flex-col space-y-3 pt-4">
                  <LemonSqueezyOverlay
                    variantId={pkg.lemon_variant_id || ''}
                    packageId={pkg.id}
                    onSuccess={handlePaymentSuccess}
                    buttonLabel="Purchase Credits"
                    buttonClassName="w-full"
                  />
                  <Button 
                    onClick={() => handleCryptoPayment(pkg)}
                    variant="outline"
                    className="w-full flex items-center justify-center gap-2"
                  >
                    <Bitcoin className="h-4 w-4" />
                    Pay with Crypto
                  </Button>
                  <div className="flex justify-center space-x-3 pt-2">
                    <VisaIcon className="h-5 w-5 sm:h-6 sm:w-6 dark:text-white" />
                    <MastercardIcon className="h-5 w-5 sm:h-6 sm:w-6 dark:text-white" />
                  </div>
                </CardFooter>
              </Card>
            ))}
          </div>
        </div>
      </div>

      <Dialog open={isCurrencyDialogOpen} onOpenChange={setIsCurrencyDialogOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Select Payment Method</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label>Select Cryptocurrency</Label>
              <Select
                value={selectedCurrency}
                onValueChange={(value) => {
                  setSelectedCurrency(value);
                  // Reset network when currency changes
                  setSelectedNetwork('');
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select cryptocurrency" />
                </SelectTrigger>
                <SelectContent>
                  {CRYPTO_CURRENCIES.map((currency) => (
                    <SelectItem key={currency.value} value={currency.value}>
                      <div className="flex items-center gap-2">
                        <currency.icon className="h-4 w-4" />
                        {currency.label}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {selectedCurrency && (
              <div className="grid gap-2">
                <Label>Select Network</Label>
                <Select
                  value={selectedNetwork}
                  onValueChange={setSelectedNetwork}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select network" />
                  </SelectTrigger>
                  <SelectContent>
                    {CRYPTO_CURRENCIES.find(c => c.value === selectedCurrency)?.networks.map((network) => (
                      <SelectItem 
                        key={typeof network === 'string' ? network : network.value} 
                        value={typeof network === 'string' ? network : network.value}
                      >
                        {typeof network === 'string' 
                          ? network.charAt(0).toUpperCase() + network.slice(1)
                          : network.label
                        }
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
          <Button 
            onClick={handleCryptoPaymentConfirm}
            className="w-full"
            disabled={!selectedCurrency || !selectedNetwork}
          >
            Continue to Payment
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}