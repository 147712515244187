import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { ActiveJob, ActiveJobsState } from '@/types/validation';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Eye, RefreshCw, Clock, CheckCircle, XCircle, PauseCircle, PlayCircle, StopCircle, Download } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { useAuth } from '@/hooks/use-auth';
import { useToast } from '@/hooks/use-toast';

export function ActiveJobs() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user, loading: authLoading } = useAuth();
  const [state, setState] = useState<ActiveJobsState>({
    jobs: [],
    isLoading: true,
    error: null,
  });

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!authLoading && !user) {
      navigate('/login', { state: { from: '/active-jobs' } });
    }
  }, [user, authLoading, navigate]);

  const fetchActiveJobs = async () => {
    if (!user) return;

    try {
      const { data: jobs, error } = await supabase
        .from('validation_history')
        .select('*')
        .eq('user_id', user.id)
        .in('status', ['pending', 'processing'])
        .order('created_at', { ascending: false });

      if (error) throw error;

      const mapToActiveJob = (jobData: any): ActiveJob => ({
        id: jobData.id,
        userId: jobData.user_id,
        fileName: jobData.file_name || 'Untitled Validation',
        totalEmails: jobData.total_emails || 0,
        validEmails: jobData.valid_emails || [],
        invalidEmails: jobData.invalid_emails || [],
        riskyEmails: [],
        disposableEmails: [],
        spamTrapEmails: [],
        confidence: 0,
        provider: 'unknown',
        responseTime: 0,
        validationResult: '',
        processedEmails: (jobData.valid_emails?.length || 0) + (jobData.invalid_emails?.length || 0),
        progress: {
          total: jobData.total_emails || 0,
          processed: (jobData.valid_emails?.length || 0) + (jobData.invalid_emails?.length || 0),
          status: jobData.status || 'pending',
          percentage: jobData.progress_percentage || 0,
          startTime: new Date(jobData.created_at),
          estimatedEndTime: jobData.estimated_completion_time ? new Date(jobData.estimated_completion_time) : undefined,
        },
        status: jobData.status || 'pending',
        createdAt: new Date(jobData.created_at),
        updatedAt: new Date(jobData.updated_at),
        error: jobData.error,
        validationId: jobData.id
      });

      setState({
        jobs: Array.isArray(jobs) ? jobs.map(mapToActiveJob) : [],
        isLoading: false,
        error: null,
      });
    } catch (error) {
      setState(prev => ({
        ...prev,
        isLoading: false,
        error: error instanceof Error ? error.message : 'Failed to fetch active jobs',
      }));
      toast({
        title: "Error",
        description: "Failed to fetch active jobs. Please try again.",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    if (!user) return;
    
    fetchActiveJobs();
    
    // Set up real-time subscription
    const channel = supabase
      .channel('validation_updates')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'validation_history',
          filter: `user_id=eq.${user.id}`
        },
        () => {
          fetchActiveJobs();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [user]);

  const viewJob = (jobId: string) => {
    navigate(`/validation/${jobId}`);
  };

  const pauseJob = async (jobId: string) => {
    if (!user) return;
    try {
      // First, get the current job status
      const { data: currentJob } = await supabase
        .from('validation_history')
        .select('status')
        .eq('id', jobId)
        .eq('user_id', user.id)
        .single();

      if (currentJob?.status !== 'processing') {
        throw new Error('Can only pause processing jobs');
      }

      const { error } = await supabase
        .from('validation_history')
        .update({ 
          status: 'paused',
          updated_at: new Date().toISOString()
        })
        .eq('id', jobId)
        .eq('user_id', user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Job paused successfully",
      });
    } catch (error) {
      console.error('Error pausing job:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to pause job",
        variant: "destructive",
      });
    }
  };

  const resumeJob = async (jobId: string) => {
    if (!user) return;
    try {
      // First, get the current job status
      const { data: currentJob } = await supabase
        .from('validation_history')
        .select('status')
        .eq('id', jobId)
        .eq('user_id', user.id)
        .single();

      if (currentJob?.status !== 'paused') {
        throw new Error('Can only resume paused jobs');
      }

      const { error } = await supabase
        .from('validation_history')
        .update({ 
          status: 'processing',
          updated_at: new Date().toISOString()
        })
        .eq('id', jobId)
        .eq('user_id', user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Job resumed successfully",
      });
    } catch (error) {
      console.error('Error resuming job:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to resume job",
        variant: "destructive",
      });
    }
  };

  const stopJob = async (jobId: string) => {
    if (!user) return;
    try {
      // First, get the current job status
      const { data: currentJob } = await supabase
        .from('validation_history')
        .select('status')
        .eq('id', jobId)
        .eq('user_id', user.id)
        .single();

      if (!['processing', 'paused'].includes(currentJob?.status || '')) {
        throw new Error('Can only stop processing or paused jobs');
      }

      const { error } = await supabase
        .from('validation_history')
        .update({ 
          status: 'stopped',
          updated_at: new Date().toISOString()
        })
        .eq('id', jobId)
        .eq('user_id', user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Job stopped successfully",
      });
    } catch (error) {
      console.error('Error stopping job:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to stop job",
        variant: "destructive",
      });
    }
  };

  const retryJob = async (jobId: string) => {
    if (!user) return;
    try {
      // First, get the current job status
      const { data: currentJob } = await supabase
        .from('validation_history')
        .select('status')
        .eq('id', jobId)
        .eq('user_id', user.id)
        .single();

      if (!['failed', 'stopped'].includes(currentJob?.status || '')) {
        throw new Error('Can only retry failed or stopped jobs');
      }

      const { error } = await supabase
        .from('validation_history')
        .update({ 
          status: 'processing',
          error: null,
          updated_at: new Date().toISOString()
        })
        .eq('id', jobId)
        .eq('user_id', user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Job restarted successfully",
      });
    } catch (error) {
      console.error('Error retrying job:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to restart job",
        variant: "destructive",
      });
    }
  };

  const downloadResults = async (job: ActiveJob) => {
    if (!user) return;
    
    try {
      const { data: jobData, error } = await supabase
        .from('validation_history')
        .select('*')
        .eq('id', job.id)
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      if (!jobData) throw new Error('Job not found');

      const validEmails: string[] = jobData.valid_emails || [];
      const invalidEmails: string[] = jobData.invalid_emails || [];
      const riskyEmails: string[] = jobData.risky_emails || [];

      const csvContent = [
        'Email,Status,Reason',
        ...validEmails.map((email: string) => `${email},valid,`),
        ...invalidEmails.map((email: string) => `${email},invalid,`),
        ...riskyEmails.map((email: string) => `${email},risky,`)
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${job.fileName.replace(/\.[^/.]+$/, '')}_results.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast({
        title: "Success",
        description: "Results downloaded successfully",
      });
    } catch (error) {
      console.error('Error downloading results:', error);
      toast({
        title: "Error",
        description: "Failed to download results",
        variant: "destructive",
      });
    }
  };

  if (authLoading || state.isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            <span>Active Jobs</span>
            <RefreshCw className="animate-spin" />
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-center py-8">
            <Clock className="h-8 w-8 animate-spin text-muted-foreground" />
            <span className="ml-2">Loading active jobs...</span>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (state.error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            <span>Active Jobs</span>
            <Button variant="ghost" size="sm" onClick={fetchActiveJobs}>
              <RefreshCw className="h-4 w-4" />
            </Button>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-red-500 flex items-center justify-center py-4">
            <XCircle className="h-5 w-5 mr-2" />
            {state.error}
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <span>Active Jobs</span>
          <Button variant="ghost" size="sm" onClick={fetchActiveJobs}>
            <RefreshCw className="h-4 w-4" />
          </Button>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>File Name</TableHead>
              <TableHead>Progress</TableHead>
              <TableHead>Started</TableHead>
              <TableHead>Status</TableHead>
              <TableHead className="w-[100px]">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {state.jobs.map((job) => (
              <TableRow key={job.id}>
                <TableCell className="font-medium">{job.fileName}</TableCell>
                <TableCell className="w-[300px]">
                  <div className="space-y-1">
                    <Progress value={(job.processedEmails / job.totalEmails) * 100} className="h-2" />
                    <div className="flex justify-between text-xs text-muted-foreground">
                      <span>{job.processedEmails} of {job.totalEmails} emails</span>
                      <span>{Math.round((job.processedEmails / job.totalEmails) * 100)}%</span>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="text-muted-foreground">
                  {formatDistanceToNow(job.createdAt, { addSuffix: true })}
                </TableCell>
                <TableCell>
                  <div className="flex items-center">
                    {job.status === 'processing' ? (
                      <Clock className="h-4 w-4 text-blue-500 mr-1 animate-spin" />
                    ) : job.status === 'completed' ? (
                      <CheckCircle className="h-4 w-4 text-green-500 mr-1" />
                    ) : job.status === 'failed' ? (
                      <XCircle className="h-4 w-4 text-red-500 mr-1" />
                    ) : (
                      <Clock className="h-4 w-4 text-yellow-500 mr-1" />
                    )}
                    <span className={
                      job.status === 'processing' ? 'text-blue-500' :
                      job.status === 'completed' ? 'text-green-500' :
                      job.status === 'failed' ? 'text-red-500' :
                      'text-yellow-500'
                    }>
                      {job.status.charAt(0).toUpperCase() + job.status.slice(1)}
                    </span>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-1">
                    {/* Download Button */}
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => downloadResults(job)}
                      className="hover:bg-muted"
                      disabled={job.status === 'pending' || job.status === 'processing'}
                    >
                      <Download className="h-4 w-4" />
                    </Button>

                    {/* Pause/Resume Button */}
                    {job.status === 'processing' && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => pauseJob(job.id)}
                        className="hover:bg-muted"
                      >
                        <PauseCircle className="h-4 w-4" />
                      </Button>
                    )}
                    {job.status === 'paused' && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => resumeJob(job.id)}
                        className="hover:bg-muted"
                      >
                        <PlayCircle className="h-4 w-4" />
                      </Button>
                    )}

                    {/* Stop Button */}
                    {(job.status === 'processing' || job.status === 'paused') && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => stopJob(job.id)}
                        className="hover:bg-muted text-red-500 hover:text-red-600"
                      >
                        <StopCircle className="h-4 w-4" />
                      </Button>
                    )}

                    {/* Retry Button */}
                    {(job.status === 'failed' || job.status === 'stopped') && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => retryJob(job.id)}
                        className="hover:bg-muted text-blue-500 hover:text-blue-600"
                      >
                        <RefreshCw className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {state.jobs.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} className="h-24 text-center text-muted-foreground">
                  No active jobs
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
